import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'

const CategoryTemplate = (props) => {
  const { data } = props;

  const {
    wpParticipantCategory: { slug, name : categoryName, seo}
  } = data

  const { title: seoName, metaDesc,  metaRobotsNofollow, metaRobotsNoindex } = seo;

  return (
    <>
      <Seo slug={slug} title={categoryName} description={metaDesc} noFollow={metaRobotsNofollow} noIndex={metaRobotsNoindex} seoTitle={seoName} />
    </>
  )
}

export const query = graphql`
    query AllParticipantsCategory($categoryDatabaseId: Int! = 5){

    wpParticipantCategory(databaseId: {eq:  $categoryDatabaseId}) {
        id
        slug
        uri
        link
        description
        databaseId
        name
        seo {
          metaDesc
          title
        }
      }
      allWpParticipant(sort: {order: ASC, fields: mi_participant___miParticipantShorttitle}) {
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(
                    maxHeight: 540, 
                    maxWidth: 960, 
                    quality: 80, 
                    cropFocus: CENTER,
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
    }
`

export default CategoryTemplate
